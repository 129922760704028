import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ingot-grid-string-filter-menu',
  templateUrl: './ingot-grid-string-filter-menu.component.html',
  styleUrls: ['./ingot-grid-string-filter-menu.component.scss']
})
export class IngotGridStringFilterMenuComponent implements OnInit {

  @Input() column: any;
  @Input() filter: any;
  @Input() filterService: any;

  constructor() { }

  ngOnInit(): void {
  }

}
