<div class="grid-container">
  <kendo-grid
    #customsGrid
    [data]="ingotGrid.gridData"
    [style.height.%]="100"
    [pageable]="gridConfig.pageable"
    [sortable]="true"
    filterable="row"
    [pageSize]="gridConfig.pageSize"
    [columnMenu]="{ filter: false }"
    [loading]="gridState.loading"
    [filter]="gridState.filter"
    (filterChange)="ingotGrid.filterChange($event)"
    [skip]="gridState.skip"
    (pageChange)="ingotGrid.pageChange($event)"
    [sort]="gridState.sort"
    (sortChange)="ingotGrid.sortChange($event)"
    (cellClick)="cellClickHandler($event)"
    (cellClose)="cellCloseHandler($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <div fxFlex fxLayout="row" fxLayoutAlign="end">
        <div class="period-selection">
          <label>Show from: </label>
          <kendo-dropdownlist class="period-dropdownlist" [(ngModel)]="timePeriodFilter" [data]="timePeriods"
                              textField="text" valueField="period"
                              (selectionChange)="changeTimePeriod($event)"></kendo-dropdownlist>
        </div>
        <div class="refresh-selection">
          <label>Refresh: </label>
          <kendo-dropdownlist class="refresh-dropdownlist" [(ngModel)]="autoRefreshInterval" [data]="autoRefreshIntervals"
                              textField="text" valueField="interval"
                              (selectionChange)="autoRefresh($event)"></kendo-dropdownlist>
        </div>
        <div class="refresh-selection">
          <label>Error Log: </label>
          <mat-slide-toggle [(ngModel)]="hasErrors" (change)="hasErrorFilter(hasErrors)" color="warn"></mat-slide-toggle>
        </div>
      </div>
      <button kendoButton [icon]="'excel'" matTooltip="Export to Excel" (click)="exportCustomsData()"></button>
      <button kendoButton [icon]="'filter-clear'" matTooltip="Clear Filters" (click)="clearFilters()" [disabled]="!gridState.filter || (gridState.filter && gridState.filter.filters.length < 1)"></button>
      <button kendoButton [icon]="'refresh'" matTooltip="Refresh Data" (click)="loadCustomsData()"></button>
    </ng-template>
    <!--<editor-fold desc="Grid Columns">-->
    <kendo-grid-column width="40" [columnMenu]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="get(dataItem, 'error_codes', []).length > 0">
          <kendo-icon [name]="'warning'" [size]="'small'" [themeColor]="'error'" style="font-size: 15px;" matTooltip="{{map(dataItem.error_codes, 'name').join(', ')}}"></kendo-icon>
        </span>
        <span *ngIf="get(dataItem, 'error_codes', []).length < 1 && get(dataItem, 'mrn_declaration_status.name')">
          <kendo-icon [name]="'success'" [size]="'small'" *ngIf="get(dataItem, 'mrn_declaration_status.name') === 'Declaration Accepted'" style="font-size: 15px;" matTooltip="Declaration Accepted"></kendo-icon>
          <kendo-icon [name]="'download'" [size]="'small'" *ngIf="get(dataItem, 'mrn_declaration_status.name') === 'Downloaded'" style="font-size: 15px;" matTooltip="Downloaded"></kendo-icon>
          <kendo-icon [name]="'gear'" [size]="'small'" *ngIf="get(dataItem, 'mrn_declaration_status.name') === 'Mapping In Progress'" style="font-size: 15px;" matTooltip="Mapping In Progress"></kendo-icon>
          <kendo-icon [name]="'minus-outline'" [size]="'small'" *ngIf="get(dataItem, 'mrn_declaration_status.name') === 'Awaiting Customs Clearance'" style="font-size: 15px;" matTooltip="Awaiting Customs Clearance"></kendo-icon>
          <kendo-icon [name]="'cancel-outline'" [size]="'small'"
                      *ngIf="![
                        'Declaration Accepted',
                        'Downloaded',
                        'Mapping In Progress',
                        'Awaiting Customs Clearance'
                      ].includes(get(dataItem, 'mrn_declaration_status.name'))"
                      style="font-size: 15px;"
                      [matTooltip]="get(dataItem, 'mrn_declaration_status.name', '')"></kendo-icon>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="id" title="ID" width="250" [hidden]="true">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="edi_number" title="IDOC Number" width="250" [hidden]="true">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="edi_filename" title="IDOC Filename" width="600" [filterable]="false" [hidden]="true"></kendo-grid-column>

    <kendo-grid-column field="declaration_type" title="Declaration Type" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="declaration_reference" title="Declaration Ref" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="mrn_declaration_status" title="Status" width="250">
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-ingot-grid-string-column-template [dataItem]="dataItem" path="mrn_declaration_status.name"></app-ingot-grid-string-column-template>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="mrn_declaration_status_description" title="Status Description" width="250" [filterable]="false" [hidden]="true">
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-ingot-grid-string-column-template [dataItem]="dataItem" path="mrn_declaration_status.description"></app-ingot-grid-string-column-template>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="shipment_reference" title="Shipment Ref" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="invoice_reference" title="Invoice No" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="delivery_number" title="Delivery No" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains" [showOperators]="false"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="rre" title="UCN" width="200">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-checkbox-filter-cell [filter]="filter" field="rre"></app-ingot-grid-checkbox-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <input type="checkbox" [(ngModel)]="dataItem.rre" (change)="rreChange(dataItem)"/>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="error_codes" title="Error Code(s)" width="250">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="(dataItem.error_codes || []).length > 0">{{map(dataItem.error_codes, 'name').join(', ')}}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <kendo-multiselect [(ngModel)]="dataItem.error_codes"
                           [data]="codeService.errorCodes"
                           [filterable]="true"
                           [kendoDropDownFilter]="this.errorCodeFilterSettings"
                           textField="name"
                           valueField="id"></kendo-multiselect>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-multi-select-filter-cell
          [filter]="filter"
          field="error_codes"
          [values]="codeService.errorCodes">
        </app-ingot-grid-multi-select-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="entry_state" title="Error State" width="250">
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-ingot-grid-string-column-template [dataItem]="dataItem" path="entry_state.name"></app-ingot-grid-string-column-template>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <div>
          <kendo-dropdownlist [(ngModel)]="dataItem.entry_state"
                              [data]="codeService.entryStates"
                              textField="name"
                              valueField="id"></kendo-dropdownlist>
          <button kendoButton [icon]="'clear'" (click)="dataItem.entry_state = null" matTooltip="Clear" [disabled]="!dataItem.entry_state" style="margin-left: 5px;"></button>
        </div>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-drop-down-filter-cell
          [filter]="filter"
          field="entry_state"
          [values]="codeService.entryStates"
        >
        </app-ingot-grid-drop-down-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="notes" title="Notes" width="300" [filterable]="false">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <textarea [(ngModel)]="dataItem.notes" maxlength="512"></textarea>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="exporter_importer" title="Importer/Exporter" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="eori_number" title="EORI" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="consignee_consignor" title="Consignor/Consignee" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="ucr" title="UCR" width="350">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="haulier" title="Haulier" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="container_no" title="Container No" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="port_exit" title="Port Exit" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="port_entry" title="Port Entry" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dispatch_country" title="Dispatch Country" width="250" [hidden]="true">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="destination_country" title="Destination Country" width="250" [hidden]="true">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="goods_location" title="Goods Location" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter" [availableOperators]="['contains', 'startswith', 'eq', 'isnull']"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="mrn" title="MRN" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="epu" title="EPU" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="entry_number" title="Entry Number" width="250">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="ucn" title="UCN Reference" width="250" [hidden]="true">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-string-filter-cell [column]="column" [filter]="filter"></app-ingot-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="ipaff_required" title="IPAFF Required" width="200">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-ingot-grid-checkbox-filter-cell [filter]="filter" field="ipaff_required"></app-ingot-grid-checkbox-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="get(dataItem, 'ipaff_required') == true">
          <kendo-icon [name]="'checkmark'" style="font-size: 30px;" matTooltip="IPAFF Required"></kendo-icon>
        </span>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex"
                 [kendoGridDetailTemplateShowIf]="doesItemDetailExist">
      <p class="detail-row-style">{{dataItem.ipaff_item_detail}}</p>
    </ng-template>

    <kendo-grid-column field="entry_date" title="Entry Date" width="260" filter="date" format="{0:dd/MM/yyyy HH:mm:ss}">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-ingot-grid-date-range-filter-cell
          class="date-range-filter"
          [filter]="filter"
          field="entry_date">
        </app-ingot-grid-date-range-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="created_date" title="Received Date" width="260" filter="date" format="{0:dd/MM/yyyy HH:mm:ss}">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-ingot-grid-date-range-filter-cell
          class="date-range-filter"
          [filter]="filter"
          field="created_date">
        </app-ingot-grid-date-range-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column width="180" *ngIf="checkEmailPermission()">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoButton (click)="confirmCopyToChief(dataItem)"
                *ngIf="checkDeclarationDate(dataItem.created_date)" [icon]="'reset'" matTooltip="Copy To Chief">CHIEF</button>
        <button kendoButton (click)="confirmCopyToCds(dataItem)"
                *ngIf="checkDeclarationDate(dataItem.created_date)" [icon]="'reload'" matTooltip="Copy To CDS">CDS</button>
      </ng-template>
    </kendo-grid-column>

    <!--</editor-fold>-->
    <ng-template kendoGridNoRecordsTemplate>
      {{gridLoading ? '' : 'No customs entries found.'}}
    </ng-template>
  </kendo-grid>
</div>
