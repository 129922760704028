import {Component, Input, OnInit} from '@angular/core';
import {BaseFilterCellComponent, FilterService} from '@progress/kendo-angular-grid';
import {FilterDescriptor} from '@progress/kendo-data-query';

@Component({
  selector: 'app-ingot-grid-date-range-filter-cell',
  templateUrl: './ingot-grid-date-range-filter-cell.component.html',
  styleUrls: ['./ingot-grid-date-range-filter-cell.component.scss']
})
export class IngotGridDateRangeFilterCellComponent extends BaseFilterCellComponent {
  @Input()
  public filter: any;

  @Input()
  public field: string;

  formatPlaceholder = {
    year: 'yyyy',
    month: 'mm',
    day: 'dd',
    hour: 'h',
    minute: 'm',
    second: 's'
  };

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public get start(): Date {
    const first = this.findByOperator('gte');

    return (first || {} as FilterDescriptor).value;
  }

  public get end(): Date {
    const end = this.findByOperator('lte');
    return (end || {} as FilterDescriptor).value;
  }

  public get hasFilter(): boolean {
    return this.filtersByField(this.field).length > 0;
  }

  public clearFilter(): void {
    this.filterService.filter(
      this.removeFilter(this.field)
    );
  }

  public filterRange(start: Date, end: Date): void {
    this.filter = this.removeFilter(this.field);

    const filters = [];

    if (start) {
      filters.push({
        field: this.field,
        operator: 'gte',
        value: start
      });
    }

    if (end) {
      filters.push({
        field: this.field,
        operator: 'lte',
        value: end
      });
    }

    const root = this.filter || {
      logic: 'and',
      filters: []
    };

    if (filters.length) {
      root.filters.push(...filters);
    }

    this.filterService.filter(root);
  }

  private findByOperator(op: string): FilterDescriptor {
    return this.filtersByField(this.field)
      .filter(({ operator }) => operator === op)[0];
  }
}
