import {IIngotGridState} from './ingot-grid.model';

export class IngotGridState implements IIngotGridState {

  skip = 0;
  loading = false;

  constructor() {
  }

}
