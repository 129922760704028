import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridModule } from '@progress/kendo-angular-grid';
import { IngotGridColumnFilterTemplatesModule } from './ingot-grid-column-filter-templates/ingot-grid-column-filter-templates.module';
import {IngotGridColumnTemplatesModule} from './ingot-grid-column-templates/ingot-grid-column-templates.module';


@NgModule({
  providers:  [],
  declarations: [],
  imports: [
    CommonModule,
    GridModule,
    IngotGridColumnTemplatesModule,
    IngotGridColumnFilterTemplatesModule
  ],
  exports: [
    IngotGridColumnTemplatesModule,
    IngotGridColumnFilterTemplatesModule
  ]
})
export class IngotGridModule { }
