<kendo-daterange>
  <kendo-dateinput
    class="range-filter"
    kendoDateRangeStartInput
    [format]="'dd/MM/yyyy'"
    [formatPlaceholder]="formatPlaceholder"
    [value]="start"
    (valueChange)="filterRange($event, end)"
    style="width:100px">
  </kendo-dateinput>
  -
  <kendo-dateinput
    class="range-filter"
    kendoDateRangeEndInput
    [format]="'dd/MM/yyyy'"
    [formatPlaceholder]="formatPlaceholder"
    [value]="end"
    (valueChange)="filterRange(start, $event)"
    style="width:100px">
  </kendo-dateinput>
</kendo-daterange>
<button
  *ngIf="hasFilter"
  class="k-button k-button-icon"
  title="Clear"
  (click)="clearFilter()">
  <span class="k-icon k-i-filter-clear"></span>
</button>
