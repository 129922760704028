import {Component, Input} from '@angular/core';
import {BaseFilterCellComponent, FilterService} from '@progress/kendo-angular-grid';
import {FilterDescriptor} from '@progress/kendo-data-query';

interface IValue {
  id: any;
  name: string;
}

@Component({
  selector: 'app-ingot-grid-drop-down-filter-cell',
  templateUrl: './ingot-grid-drop-down-filter-cell.component.html',
  styleUrls: ['./ingot-grid-drop-down-filter-cell.component.scss']
})
export class IngotGridDropDownFilterCellComponent extends BaseFilterCellComponent {

  @Input()
  public filter: any;

  @Input()
  public field: string;

  @Input()
  public values: Array<IValue>;

  // public selectedValue: IValue;

  constructor(filterService: FilterService) {
    super(filterService);
  }

  get selectedValue(): IValue {
    const filter = this.filterByField(this.field);
    if (filter){
      return this.values.find(e => e.id === filter.value);
    }
    return undefined;
  }

  get hasFilter(): boolean {
    return !!this.filterByField(this.field);
  }

  clearFilter(): void {
    this.filterService.filter(
      this.removeFilter(this.field)
    );
  }

  filterValue(value): void {
    this.filter = this.removeFilter(this.field);

    const filters = [];

    if (value) {
      filters.push({
        field: this.field,
        operator: 'eq',
        value: value.id
      });
    }

    const root = this.filter || {
      logic: 'and',
      filters: []
    };

    if (filters.length) {
      root.filters.push(...filters);
    }

    this.filterService.filter(root);
  }

}
