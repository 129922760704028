import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ICustomsDataModel} from './customs-data.model';
import {IngotGridService} from '../ingot-grid/ingot-grid.service';
import {IGridExternalFilter, IIngotGridConfig, IIngotGridState} from '../ingot-grid/ingot-grid.model';
import { saveAs } from 'file-saver';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import { get, map, remove, find } from 'lodash';
import {GridComponent} from '@progress/kendo-angular-grid';
import {EntryCodesService} from "./entry-codes.service";
import {DropDownFilterSettings} from "@progress/kendo-angular-dropdowns";
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CustomsGridConfirmationComponent} from "./confirmation-dialog/customs-grid-confirmation.component";
import { Auth } from 'aws-amplify';

interface Interval {
  interval: number;
  text: string;
}

interface TimePeriod {
  period: string;
  text: string;
  filters?: Array<IGridExternalFilter>;
}

@Component({
  selector: 'app-customs-grid',
  templateUrl: './customs-grid.component.html',
  styleUrls: ['./customs-grid.component.scss'],
  providers: [IngotGridService]
})
export class CustomsGridComponent implements OnInit, AfterViewInit, OnDestroy {

  get = get;
  map = map;
  remove = remove;
  find = find;

  customsData;
  gridLoading = false;
  autoRefreshId;
  autoRefreshIntervals: Array<Interval> = [
    {
      interval: 0,
      text: 'Off'
    },
    {
      interval: 15,
      text: '15 Minutes'
    },
    {
      interval: 30,
      text: '30 Minutes'
    },
    {
      interval: 60,
      text: '60 Minutes'
    }
  ];
  autoRefreshInterval = this.autoRefreshIntervals[1];
  timePeriods: Array<TimePeriod> = [
    {
      period: 'all',
      text: 'All'
    },
    {
      period: 'today',
      text: 'Today',
      filters: [
        {id: 'createdDateLower', field: 'created_date', operator: 'eq', value: moment().startOf('day')}
      ]
    },
    {
      period: 'week',
      text: 'Past Week',
      filters: [
        {id: 'createdDateLower', field: 'created_date', operator: 'gte', value: moment().startOf('week')},
        {id: 'createdDateUpper', field: 'created_date', operator: 'lte', value: moment().startOf('day')}
      ]
    },
    {
      period: 'month',
      text: 'Past Month',
      filters: [
        {id: 'createdDateLower', field: 'created_date', operator: 'gte', value: moment().startOf('month')},
        {id: 'createdDateUpper', field: 'created_date', operator: 'lte', value: moment().startOf('day')}
      ]
    },
    {
      period: 'year',
      text: 'Past Year',
      filters: [
        {id: 'createdDateLower', field: 'created_date', operator: 'gte', value: moment().startOf('year')},
        {id: 'createdDateUpper', field: 'created_date', operator: 'lte', value: moment().startOf('day')}
      ]
    }
  ];
  timePeriodFilter = this.timePeriods[0];
  activeTimePeriodFilter = this.timePeriodFilter.filters;

  public errorCodeFilterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  gridFilter;
  gridSort;
  gridPageSize = 2;
  gridSkip = 0;
  email;

  gridConfig: IIngotGridConfig;
  gridState: IIngotGridState;

  gridPageSubscriber;

  currentErrorCodes = [];
  errorCodesFilter;

  rreFilter = false;
  entryStatesFilter;

  editableFields = [
    {
      field: 'error_codes',
      patchData: (dataItem) => {
        return {error_codes: this.map(dataItem.error_codes, 'id')};
      }
    },
    {
      field: 'entry_state',
      patchData: (dataItem) => {
        return {entry_state: this.get(dataItem, 'entry_state.id', null)};
      }
    },
    {
      field: 'notes',
      patchData: (dataItem) => {
        return {notes: this.get(dataItem, 'notes', '')};
      }
    }
  ];
  editableFieldsArray: Array<string> = this.map(this.editableFields, 'field');
  currentEditableFieldValues = {};

  hasErrors = false;

  @ViewChild('customsGrid') private customsGrid: GridComponent;

  constructor(public ingotGrid: IngotGridService, private http: HttpClient, public codeService: EntryCodesService, public dialog: MatDialog, private _snackBar: MatSnackBar) {
    ingotGrid.enablePaging(100);
    ingotGrid.readCall('customsdata');
    ingotGrid.postRead((gridData) => {
      if (gridData && gridData.data){
        gridData.data.forEach(item => {
          if (item.created_date){
            item.created_date = new Date(item.created_date);
          }
          if (item.entry_date){
            item.entry_date = new Date(item.entry_date);
          }
        });
      }
    });
    ingotGrid.GridSort = [{dir: 'desc', field: 'created_date'}];

    this.gridConfig = ingotGrid.GridConfig;
    this.gridState = ingotGrid.GridState;
    this.gridSort = ingotGrid.GridSort;

    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => this.email = user.attributes.email)
      .catch();
  }

  ngOnInit(): void {
    this.autoRefresh(this.autoRefreshInterval);
  }

  public ngAfterViewInit(): void {
    /*this.gridPageSubscriber = this.customsGrid.pageChange.pipe(debounceTime(250))
      .subscribe((e) => this.ingotGrid.pageChange(e));*/
  }

  loadCustomsData(): void {
    this.ingotGrid.loadGridData();
  }

  autoRefresh(interval: Interval): void {
    this.clearAutoRefresh();
    if (interval.interval > 0){
      const ms = interval.interval * 60 * 1000;
      this.loadCustomsData();
      this.autoRefreshId = setInterval(() => {
        this.loadCustomsData();
      }, ms);
    }
  }

  clearAutoRefresh(): void {
    if (this.autoRefreshId){
      clearInterval(this.autoRefreshId);
    }
  }

  changeTimePeriod(period: TimePeriod): void {
    this.ingotGrid.gridFilters.removeExternalFilter('createdDateLower');
    this.ingotGrid.gridFilters.removeExternalFilter('createdDateUpper');
    if (period.filters){
      this.activeTimePeriodFilter = period.filters;
      this.activeTimePeriodFilter.forEach((filter) => {
        this.ingotGrid.gridFilters.addExternalFilter(filter);
      });
    }
    else {
      this.activeTimePeriodFilter = null;
    }
    this.ingotGrid.gridState.skip = 0;
    this.loadCustomsData();
  }

  rreChange(dataItem): void {
    this.http.patch(`customsdata/${dataItem.id}`, {
      rre: dataItem.rre
    }).subscribe();
  }

  hasErrorFilter(hasError): void {
    this.ingotGrid.gridFilters.removeExternalFilter('hasErrorCode');
    this.ingotGrid.gridFilters.removeExternalFilter('hasErrorState');
    if (hasError){
      this.ingotGrid.gridFilters.addExternalFilter({
        id: 'hasErrorCode',
        field: 'error_codes',
        operator: 'neq',
        value: null
      });
      this.ingotGrid.gridFilters.addExternalFilter({
        id: 'hasErrorState',
        field: 'entry_state',
        operator: 'neq',
        value: null
      });
    }
    this.ingotGrid.gridState.skip = 0;
    this.loadCustomsData();
  }

  exportCustomsData(): void {
    const params: any = {};
    const filters = this.ingotGrid.gridFilters.processGridFiltersForApi();
    if (filters.filters.length > 0){
      params.filters = JSON.stringify(filters);
    }
    if (this.ingotGrid.gridState.sort && this.ingotGrid.gridState.sort.length > 0){
      if (this.ingotGrid.gridState.sort[0].dir && this.ingotGrid.gridState.sort[0].field){
        params.sort = JSON.stringify(this.ingotGrid.gridState.sort[0]);
        params.sortDir = this.ingotGrid.gridState.sort[0].dir;
        params.sortField = this.ingotGrid.gridState.sort[0].field;
      }
    }
    this.http.get('export', {
      params,
      headers: {Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
      responseType: 'blob'
    }).subscribe((data: any) => {
      const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
      const fileName = 'customs-data_' + moment().format('DD-MM-YYYY_HH-mm-ss') + '.xlsx';
      saveAs(blob, fileName);
    });
  }

  cellClickHandler({ sender, rowIndex, columnIndex, dataItem, isEdited }): void {
    if (!isEdited) {
      const field = this.ingotGrid.getFieldOfIndex(sender, columnIndex);
      if (this.editableFieldsArray.includes(field)){
        this.currentEditableFieldValues[field] = dataItem[field];
        sender.editCell(rowIndex, columnIndex);
      }
    }
  }

  cellCloseHandler(args: any): void {
    const field = args.column.field;
    const dataItem = args.dataItem;
    if (this.editableFieldsArray.includes(field)){
      if (this.currentEditableFieldValues[field] !== dataItem[field]){
        const patchData = this.find(this.editableFields, {field}).patchData(dataItem);
        this.http.patch(`customsdata/${dataItem.id}`, patchData).subscribe();
      }
    }
  }

  doesItemDetailExist(dataItem: any, rowIndex: number): boolean {
    if (dataItem.ipaff_item_detail) {
      return true;
    } else {
      return false;
    }
  }

  clearFilters(): void {
    this.ingotGrid.gridFilters.clearGridFilters();
    this.gridState.filter = {
      logic: 'and',
      filters: []
    };
    this.ingotGrid.gridState.skip = 0;
    this.loadCustomsData();
  }

  ngOnDestroy(): void {
    if (this.gridPageSubscriber){
      this.gridPageSubscriber.unsubscribe();
    }
  }

  public checkEmailPermission() {
    return ((this.email.includes('warrant-group.com') || this.email.includes('ingotportal.com')));
  }

  public checkDeclarationDate(createdDate) {
    return (new Date(createdDate) > new Date('2022-09-27T00:00:00Z'));
  }

  public confirmCopyToChief(dataItem) {
    const dialogRef = this.dialog.open(CustomsGridConfirmationComponent, {
      data: {
        customsSystem: 'CHIEF'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        this.http.post(`idoc`, {
          "action": "copy",
          "source": "s3://basf-customs-solution/mapping/in/cds/" + dataItem.edi_filename.substr(0, dataItem.edi_filename.lastIndexOf('.')) + ".json",
          "destination": "s3://basf-customs-solution/mapping/in/chief/" + dataItem.edi_filename.substr(0, dataItem.edi_filename.lastIndexOf('.')) + ".json"
        }).subscribe(
          (response) => {
            this._snackBar.open('Declaration coped to CHIEF successfully.', '',{
              duration: 3000
            })
          },
          (error) => {
            this._snackBar.open('Declaration could not be copied.', '',{
              duration: 3000
            })
          }
        );
      }
    });
  }

  public confirmCopyToCds(dataItem) {
    const dialogRef = this.dialog.open(CustomsGridConfirmationComponent, {
      data: {
        customsSystem: 'CDS'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == true) {
        this.http.post(`idoc`, {
          "action": "copy",
          "source": "s3://basf-customs-solution/mapping/in/chief/" + dataItem.edi_filename.substr(0, dataItem.edi_filename.lastIndexOf('.')) + ".json",
          "destination": "s3://basf-customs-solution/mapping/in/cds/" + dataItem.edi_filename.substr(0, dataItem.edi_filename.lastIndexOf('.')) + ".json"
        }).subscribe(
          (response) => {
            this._snackBar.open('Declaration coped to CDS successfully.', '',{
              duration: 3000
            })
          },
          (error) => {
            this._snackBar.open('Declaration could not be reprocessed.', '',{
              duration: 3000
            })
          }
        );
      }
    });
  }


}
