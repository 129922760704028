import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class EntryCodesService {
  private _entryStates = [];
  private _errorCodes = [];

  constructor(private http: HttpClient) {
    this.loadData();
  }

  get entryStates(): any[] {
    return this._entryStates;
  }

  get errorCodes(): any[] {
    return this._errorCodes;
  }

  private loadData() {
    this.http.get(`autocomplete/entry-states`).subscribe(data => {
      this._entryStates = data as [];
    });

    this.http.get(`autocomplete/error-codes`).subscribe(data => {
      this._errorCodes = data as [];
    });
  }
}
