import {Injectable, OnDestroy} from '@angular/core';
import {PageChangeEvent} from '@progress/kendo-angular-grid';

import {get, set, map, remove} from 'lodash';
import {IngotGridConfig} from './ingot-grid-config';
import {IngotGridState} from './ingot-grid-state';
import {IngotGridFilters} from './ingot-grid-filters';
import {IGridFilter, IIngotGridConfig, IIngotGridState} from './ingot-grid.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IngotGridService implements OnDestroy {

  private get = get;
  private set = set;
  private map = map;
  private remove = remove;

  gridDataSubscriber;
  gridData: any;

  gridConfig: IIngotGridConfig = new IngotGridConfig();
  gridState: IIngotGridState = new IngotGridState();
  gridFilters = new IngotGridFilters();

  constructor(private http: HttpClient) { }

  preRead(preRead?): void {
    this.gridConfig.preRead = preRead;
  }

  readCall(readCall): void {
    this.gridConfig.readCall = readCall;
  }

  postRead(postRead?): void {
    this.gridConfig.postRead = postRead;
  }

  enablePaging(pageSize: number): void {
    this.gridConfig.pageable = true;
    this.gridConfig.pageSize = pageSize;
  }

  loadGridData(): void {
    if (this.gridConfig.readCall){
        this.gridState.loading = this.gridConfig.showLoader === true;
        const params: any = {};
        if (this.gridConfig.pageable){
          params.limit = this.gridConfig.pageSize;
          params.offset = this.gridState.skip;
          params.paginated = 1;
        }
        if (this.gridState.sort && this.gridState.sort.length > 0){
          if (this.gridState.sort[0].dir && this.gridState.sort[0].field){
            params.sort = JSON.stringify(this.gridState.sort[0]);
            params.sortDir = this.gridState.sort[0].dir;
            params.sortField = this.gridState.sort[0].field;
          }
        }
        const filters = this.gridFilters.processGridFiltersForApi();
        if (filters.filters.length > 0){
          params.filters = JSON.stringify(filters);
        }
        if (this.gridConfig.preRead){
          try {
            this.gridConfig.preRead(params);
          }
          catch (e){
            console.error(e);
          }
        }
        this.gridDataSubscriber = this.http.get(this.gridConfig.readCall, {
          params
        }).subscribe((gridData: any) => {
          if (gridData && gridData.code && gridData.code === 500){
            this.gridData = {
              data: [],
              total: 0
            };
          }
          else {
            if (this.gridConfig.postRead){
              try {
                this.gridConfig.postRead(gridData);
              }
              catch (e){
                console.error(e);
              }
            }
            this.gridData = gridData;
          }
          this.gridState.loading = false;
        }, () => {
          this.gridData = {
            data: [],
            total: 0
          };
          this.gridState.loading = false;
        });
      }
  }

  pageChange(event: PageChangeEvent): void {
    this.gridState.skip = event.skip;
    this.loadGridData();
  }

  sortChange(event): void {
    this.gridState.sort = event;
    this.loadGridData();
  }

  filterChange(event): void {
    this.gridState.filter = this.gridFilters.gridFilterChange(event);
    this.gridState.skip = 0;
    this.loadGridData();
  }

  addFilter(filter: IGridFilter, reloadData = true): void {
    this.gridState.filter = this.gridFilters.addFilter(filter);
    if (reloadData){
      this.loadGridData();
    }
  }

  removeFilter(filter, reloadData = true): void {
    this.gridState.filter = this.gridFilters.removeFilter(filter);
    if (reloadData){
      this.loadGridData();
    }
  }

  clearFilters(includeSticky?: boolean): void {
    this.gridState.filter = this.gridFilters.clearGridFilters(includeSticky);
    this.filterChange(this.gridState.filter);
  }

  hasFilters(includeSticky?: boolean): boolean {
    return this.gridFilters.hasFilters(includeSticky);
  }

  hasExternalFilters(): boolean {
    return this.gridFilters.hasExternalFilters();
  }

  getFieldOfIndex(grid, index: number, includeHidden = false): string {
    const columns = this.map(this.get(grid, 'columns._results', []), (col) => {
      return {
        field: col.field,
        hidden: col.hidden
      };
    });
    if (includeHidden === false){
      this.remove(columns, {hidden: true});
    }
    return columns[index].field;
  }

  private getPage(): number {
    return ((this.gridState.skip / this.gridConfig.pageSize) + 1);
  }

  private getSkip(page: number): number {
    return this.gridConfig.pageSize * (page - 1);
  }

  get GridConfig(): IIngotGridConfig {
    return this.gridConfig;
  }

  get GridState(): IIngotGridState {
    return this.gridState;
  }

  get GridFilters(): any {
    return this.gridFilters;
  }

  get GridSort(): any {
    return this.gridState.sort;
  }

  set GridSort(sort) {
    this.gridState.sort = sort;
  }

  ngOnDestroy(): void {
    if (this.gridDataSubscriber){
      this.gridDataSubscriber.unsubscribe();
    }
  }
}
