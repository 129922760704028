import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IngotGridStringColumnTemplateComponent } from './ingot-grid-string-column-template/ingot-grid-string-column-template.component';



@NgModule({
  declarations: [IngotGridStringColumnTemplateComponent],
  exports: [
    IngotGridStringColumnTemplateComponent
  ],
  imports: [
    CommonModule
  ]
})
export class IngotGridColumnTemplatesModule { }
