import {forEach} from 'lodash';
import {remove} from 'lodash';
import {get} from 'lodash';
import {find} from 'lodash';
import {IGridExternalFilter, IGridFilter} from './ingot-grid.model';

export class IngotGridFilters {

  private forEach = forEach;
  private remove = remove;
  private get = get;
  private find = find;

  private gridFilter = {
    logic: 'and',
    filters: []
  };

  private externalFilters: Array<IGridExternalFilter> = [];

  constructor() { }

  gridFilterChange(event): object {
    this.gridFilter = event;
    return this.gridFilter;
  }

  addFilter(filter: IGridFilter): object {
    this.gridFilter.filters.push(filter);
    return this.gridFilter;
  }

  addExternalFilter(filter: IGridExternalFilter): Array<IGridExternalFilter> {
    this.externalFilters.push(filter);
    return this.externalFilters;
  }

  removeFilter(filter): object {
    const filterField = this.get(filter, 'field', filter);
    this.remove(this.gridFilter.filters, {field: filterField});
    return this.gridFilter;
  }

  removeExternalFilter(filter): Array<IGridExternalFilter> {
    const filterFieldId = this.get(filter, 'id', filter);
    this.remove(this.externalFilters, {id: filterFieldId});
    return this.externalFilters;
  }

  clearGridFilters(includeSticky = false): object {
    if (includeSticky){
      this.gridFilter.filters = [];
    }
    else {
      this.remove(this.gridFilter.filters, (f) => {
        return !f.sticky;
      });
    }
    return this.gridFilter;
  }

  clearExternalFilters(): Array<IGridExternalFilter> {
    this.externalFilters = [];
    return this.externalFilters;
  }

  hasFilters(includeSticky = false): boolean {
    const gridFilters = this.get(this.gridFilter, 'filters', []);
    if (gridFilters.length > 0){
      if (includeSticky){
        return true;
      }
      else {
        return this.find(gridFilters, (f) => {
          return !f.sticky;
        });
      }
    }
    else {
      return false;
    }
  }

  hasExternalFilters(): boolean {
    return this.externalFilters.length > 0;
  }

  get GridFilter(): any {
    return this.gridFilter;
  }

  set GridFilter(gridFilter) {
    this.gridFilter = gridFilter;
  }

  processGridFiltersForApi(): any {
    const filters = JSON.parse(JSON.stringify(this.gridFilter));
    const gridFilters = {
      logic: 'and',
      filters: []
    };
    this.forEach(filters.filters, (colFilters) => {
      if (colFilters.filters){
        this.forEach(colFilters.filters, (colFilter) => {
          this.processFilter(colFilter);
          gridFilters.filters.push(colFilter);
        });
      }
      else {
        this.processFilter(colFilters);
        gridFilters.filters.push(colFilters);
      }
    });
    this.forEach(this.externalFilters, (colFilter) => {
      this.processFilter(colFilter);
      gridFilters.filters.push(colFilter);
    });
    return gridFilters;
  }

  private processFilter(filter): void {
    if (filter.operator === 'startswith'){
      filter.operator = 'sw';
    }
    if (filter.operator === 'isnull'){
      filter.operator = 'eq';
    }
  }

}
