<kendo-grid-string-filter-menu
  [column]="column"
  [filter]="filter"
  [filterService]="filterService"
  [extra]="false"
  operator="startswith"
>
  <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
  <kendo-filter-contains-operator></kendo-filter-contains-operator>
  <kendo-filter-eq-operator></kendo-filter-eq-operator>
</kendo-grid-string-filter-menu>
