<kendo-dropdownlist
  class="filter-cell"
  [data]="values"
  textField="name"
  valueField="id"
  [value]="selectedValue"
  (valueChange)="filterValue($event)">
</kendo-dropdownlist>
<button
  kendoButton
  [icon]="'filter-clear'"
  title="Clear"
  *ngIf="hasFilter"
  style="margin-left: 5px;"
  (click)="clearFilter()">
</button>
