import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'customs-grid-confirmation',
  templateUrl: './customs-grid-confirmation.component.html',
})
export class CustomsGridConfirmationComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { customsSystem }
  ) {}
}
