import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IngotGridStringFilterMenuComponent} from './ingot-grid-string-filter-menu/ingot-grid-string-filter-menu.component';
import {GridModule} from '@progress/kendo-angular-grid';
import { IngotGridDateFilterMenuComponent } from './ingot-grid-date-filter-menu/ingot-grid-date-filter-menu.component';
import { IngotGridStringFilterCellComponent } from './ingot-grid-string-filter-cell/ingot-grid-string-filter-cell.component';
import { IngotGridDateRangeFilterCellComponent } from './ingot-grid-date-range-filter-cell/ingot-grid-date-range-filter-cell.component';
import {DateInputModule, DateRangeModule} from '@progress/kendo-angular-dateinputs';
import { IngotGridDropDownFilterCellComponent } from './ingot-grid-drop-down-filter-cell/ingot-grid-drop-down-filter-cell.component';
import {DropDownListModule, MultiSelectModule} from '@progress/kendo-angular-dropdowns';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import { IngotGridMultiSelectFilterCellComponent } from './ingot-grid-multi-select-filter-cell/ingot-grid-multi-select-filter-cell.component';
import { IngotGridCheckboxFilterCellComponent } from './ingot-grid-checkbox-filter-cell/ingot-grid-checkbox-filter-cell.component';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    IngotGridStringFilterMenuComponent,
    IngotGridDateFilterMenuComponent,
    IngotGridStringFilterCellComponent,
    IngotGridDateRangeFilterCellComponent,
    IngotGridDropDownFilterCellComponent,
    IngotGridMultiSelectFilterCellComponent,
    IngotGridCheckboxFilterCellComponent
  ],
  imports: [
    CommonModule,
    GridModule,
    DateRangeModule,
    DateInputModule,
    DropDownListModule,
    ButtonsModule,
    MultiSelectModule,
    FormsModule
  ],
  exports: [
    IngotGridStringFilterMenuComponent,
    IngotGridDateFilterMenuComponent,
    IngotGridStringFilterCellComponent,
    IngotGridDateRangeFilterCellComponent,
    IngotGridDropDownFilterCellComponent,
    IngotGridMultiSelectFilterCellComponent,
    IngotGridCheckboxFilterCellComponent
  ]
})
export class IngotGridColumnFilterTemplatesModule { }
