import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NavigationComponent } from './navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { GridModule } from '@progress/kendo-angular-grid';
import { CustomsGridComponent } from './customs-grid/customs-grid.component';
import {ApiModule} from './api/api.module';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {DropDownListModule, MultiSelectModule} from '@progress/kendo-angular-dropdowns';
import {FormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';

/* Add Amplify imports */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import Amplify from 'aws-amplify';
// @ts-ignore
import awsconfig from '../aws-exports';
import {IngotGridModule} from './ingot-grid/ingot-grid.module';
import { LogoutComponent } from './auth/logout/logout.component';
import {HttpClientModule} from '@angular/common/http';
import { IconsModule } from '@progress/kendo-angular-icons';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {CustomsGridConfirmationComponent} from "./customs-grid/confirmation-dialog/customs-grid-confirmation.component";


/* Configure Amplify resources */
Amplify.configure(awsconfig);


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    CustomsGridComponent,
    LogoutComponent,
    CustomsGridConfirmationComponent
  ],
    imports: [
        AmplifyUIAngularModule,
        ApiModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        FlexLayoutModule,
        LayoutModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatDialogModule,
        MatSnackBarModule,
        MatTooltipModule,
        GridModule,
        ButtonsModule,
        DropDownListModule,
        FormsModule,
        IngotGridModule,
        HttpClientModule,
        MultiSelectModule,
        IconsModule,
        MatSlideToggleModule
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
