<kendo-grid-string-filter-cell
  [showOperators]="showOperators"
  [column]="column"
  [filter]="filter"
  [operator]="operator"
>
  <kendo-filter-startswith-operator *ngIf="availableOperators.includes('startswith')"></kendo-filter-startswith-operator>
  <kendo-filter-contains-operator *ngIf="availableOperators.includes('contains')"></kendo-filter-contains-operator>
  <kendo-filter-eq-operator *ngIf="availableOperators.includes('eq')"></kendo-filter-eq-operator>
  <kendo-filter-isnull-operator *ngIf="availableOperators.includes('isnull')"></kendo-filter-isnull-operator>
</kendo-grid-string-filter-cell>
