import {IIngotGridConfig} from './ingot-grid.model';

export class IngotGridConfig implements IIngotGridConfig {

  columnMenu = true;
  filterable: any = 'menu';
  resizable = true;
  showLoader = true;
  statePager = false;
  pageable = false;
  pageSize: number;

  readCall?: any;

  constructor() {
  }

}
