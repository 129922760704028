import {Component, Input, OnInit} from '@angular/core';

type Operators = 'contains' | 'startswith' | 'eq' | 'isnull';

@Component({
  selector: 'app-ingot-grid-string-filter-cell',
  templateUrl: './ingot-grid-string-filter-cell.component.html',
  styleUrls: ['./ingot-grid-string-filter-cell.component.scss']
})
export class IngotGridStringFilterCellComponent implements OnInit {

  @Input() column: any;
  @Input() filter: any;
  @Input() filterService: any;
  @Input() operator: Operators = 'startswith';
  @Input() showOperators = true;
  @Input() availableOperators: Operators[] = ['contains', 'startswith', 'eq'];

  constructor() { }

  ngOnInit(): void {
  }

}
