import {Component, Input} from '@angular/core';
import {BaseFilterCellComponent, FilterService} from '@progress/kendo-angular-grid';

interface IValue {
  id: any;
  name: string;
}

@Component({
  selector: 'app-ingot-grid-multi-select-filter-cell',
  templateUrl: './ingot-grid-multi-select-filter-cell.component.html',
  styleUrls: ['./ingot-grid-multi-select-filter-cell.component.scss']
})
export class IngotGridMultiSelectFilterCellComponent extends BaseFilterCellComponent {

  @Input()
  public filter: any;

  @Input()
  public field: string;

  @Input()
  public values: Array<IValue>;

  constructor(filterService: FilterService) {
    super(filterService);
  }

  get hasFilter(): boolean {
    return !!this.filterByField(this.field);
  }

  get selectedValues(): Array<IValue> {
    const filter = this.filterByField(this.field);
    if (filter){
      return this.values.filter(e => {
        return filter.value.includes(e.id);
      });
    }
    return undefined;
  }

  clearFilter(): void {
    this.filterService.filter(
      this.removeFilter(this.field)
    );
  }

  filterValues(values): void {
    this.filter = this.removeFilter(this.field);

    const filters = [];

    if (values && values.length > 0) {
      filters.push({
        field: this.field,
        operator: 'contains',
        value: values.map(e => e.id)
      });
    }
    else{
      this.clearFilter();
    }

    const root = this.filter || {
      logic: 'and',
      filters: []
    };

    if (filters.length) {
      root.filters.push(...filters);
    }

    this.filterService.filter(root);
  }


}
