<kendo-multiselect
  class="filter-cell"
  [value]="selectedValues"
  [data]="values"
  (valueChange)="filterValues($event)"
  textField="name"
  valueField="id">
</kendo-multiselect>
<button
  kendoButton
  [icon]="'filter-clear'"
  title="Clear"
  *ngIf="hasFilter"
  style="margin-left: 5px;"
  (click)="clearFilter()">
</button>
